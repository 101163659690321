import dynamic from "next/dynamic";
import { Typography } from "@material-ui/core";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Page from "@/components/Page";
import { Langcode } from "@/config/i18n";

const NotificationForm = dynamic(() => import("@/components/NotificationForm"));

export const getStaticProps: GetStaticProps = async ({ locale = Langcode.FR }) => {
  const ssrTranslations = await serverSideTranslations(locale);
  return { props: { ...ssrTranslations } };
};

export const NotificationPage = () => {
  const { t } = useTranslation("notification");
  
  const metaTags = {
    title: t("meta.title"),
    description: t("meta.description"),
  };

  return (
    <Page metaTags={metaTags}>
      <Typography variant="h1">{t("title")}</Typography>
      <NotificationForm />
    </Page>
  );
};

export default NotificationPage;
